<template>
  <div class="outer screen text-center">
    <div class="center">
      <div v-if="!hasFirstName && !hasLastName">
        <PersonalDataScreen />
      </div>
      <div v-if="hasLastName && !hasJobposition">
        <WorkDataScreen />
      </div>
      <div v-if="hasJobposition && !hasEmail">
        <CommunicationDataScreen />
      </div>
      <div v-if="hasEmail && !hasQuestionGdpr">
        <QuestionGdprScreen />
      </div>
      <div v-if="hasQuestionGdpr && hasSavedData">
        <CodeScreen />
      </div>
      <div class="bottom-spacer"></div>
    </div>
  </div>
</template>

<script>
import PersonalDataScreen from "@/components/PersonalDataScreen.vue";
import WorkDataScreen from "@/components/WorkDataScreen.vue";
import CommunicationDataScreen from "@/components/CommunicationDataScreen.vue";
import QuestionGdprScreen from "@/components/QuestionGdprScreen.vue";
import CodeScreen from "@/components/CodeScreen.vue";

export default {
  name: "HomeView",
  components: {
    PersonalDataScreen,
    WorkDataScreen,
    CommunicationDataScreen,
    QuestionGdprScreen,
    CodeScreen,
  },
  computed: {
    hasFirstName: function () {
      if (this.$store.getters.getFirstName === "") {
        return false;
      }
      return true;
    },
    hasLastName: function () {
      if (this.$store.getters.getLastName === "") {
        return false;
      }
      return true;
    },
    hasEmail: function () {
      if (this.$store.getters.getEmail === "") {
        return false;
      }
      return true;
    },
    hasJobposition: function () {
      if (this.$store.getters.getJobposition === "") {
        return false;
      }
      return true;
    },

    hasQuestionGdpr: function () {
      if (this.$store.getters.getQuestionGdpr === null) {
        return false;
      }
      return true;
    },
    hasSavedData: function () {
      if (this.$store.getters.getSavedData === null) {
        return false;
      }
      return true;
    },
  },
};
</script>

<style>
html,
body {
  background-color: #faac1a;
  color: #fff;
  /* text-transform: uppercase; */
}
.screen {
  /* background: url("~@/assets/logo.png") no-repeat center center fixed; */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100%;
  overflow: hidden; /* Hide scrollbars */
}
.outer {
  /* display: flex; */
  /* align-items: center; */
}
.center {
  /* margin: auto; */
  padding: 10px;
}
.logo {
  padding: 15px;
}
.bottom-spacer {
  padding: 100px;
}
* {
  border-radius: 0 !important;
}
</style>
