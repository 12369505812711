<template lang="html">
  <!-- <img src="@/assets/logo.png" alt="Diensten Centrum" width="200" /> -->
  <h1 class="title text-primary">Jouw code is</h1>
  <h1 class="text-primary">{{ getSavedCode }}</h1>
</template>

<script>
export default {
  name: "CodeScreen",
  data: () => ({
    firstName: null,
    lastName: null,
    email: null,
  }),
  computed: {
    getSavedCode() {
      return this.$store.getters.getCode;
    },
  },
  methods: {},
};
</script>

<style lang="css" scoped>
.title {
  padding-bottom: 10px;
  font-size: 30px;
  color: #fff;
}
</style>
