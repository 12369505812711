<template>
  <InactiveScreen v-if="getGameState == 'inactive'" />
  <EnterCodeScreen v-if="getGameState == 'entercode'" />
  <StartScreen v-if="getGameState == 'active' && getPlayerState == 'start'" />
  <GuessScreen v-if="getGameState == 'active' && getPlayerState == 'guess'" />
  <WonScreen v-if="getGameState == 'active' && getPlayerState == 'won'" />
  <LooseScreen v-if="getGameState == 'active' && getPlayerState == 'loose'" />
</template>

<script>
import InactiveScreen from "@/components/InactiveScreen.vue";
import EnterCodeScreen from "@/components/EnterCodeScreen.vue";
import StartScreen from "@/components/StartScreen.vue";
import GuessScreen from "@/components/GuessScreen.vue";
import WonScreen from "@/components/WonScreen.vue";
import LooseScreen from "@/components/LooseScreen.vue";

export default {
  name: "GameView",
  components: {
    InactiveScreen,
    EnterCodeScreen,
    StartScreen,
    GuessScreen,
    WonScreen,
    LooseScreen,
  },
  computed: {
    getGameState: function () {
      return this.$store.getters.getGameState;
    },
    getPlayerState: function () {
      return this.$store.getters.getPlayerState;
    },
  },
  created() {
    this.$store.commit("setGameState", "inactive");
  },
  methods: {},
};
</script>

<style>
h1 {
  font-size: 120px;
  color: #fff;
  padding-bottom: 20px;
}
img {
  padding-bottom: 20px;
}
.outer {
  display: flex;
  align-items: center;
}
.center {
  margin: auto;
  padding: 10px;
}
.screen {
  /* background: url("~@/assets/home_bg.png") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100%; */
  overflow: hidden; /* Hide scrollbars */
}
.home-featured {
  background-color: #e5e5e5;
  /* background-image: linear-gradient(to top right, purple, violet, orange); */
  background: linear-gradient(#512d6d, #faac1a);
  background-size: 600% 600%;
  -webkit-animation: gradanimate 0.5s ease infinite;
  animation: gradanimate 10s ease infinite;
}
@-webkit-keyframes gradanimate {
  0% {
    background-position: 2% 0%;
  }
  50% {
    background-position: 99% 100%;
  }
  100% {
    background-position: 2% 0%;
  }
}
@keyframes gradanimate {
  0% {
    background-position: 2% 0%;
  }
  50% {
    background-position: 99% 100%;
  }
  100% {
    background-position: 2% 0%;
  }
}
</style>
