<template lang="html">
  <!-- <img src="@/assets/logo.png" alt="Diensten Centrum" width="200" /> -->
  <h2 class="title text-primary">
    Ik ga akkoord met het verwerken van mijn persoonlijke gegevens zoals
    beschreven in het
    <a href="https://www.aethon.nl/privacy-statement/" target="_blank"
      >privacybeleid</a
    >
  </h2>
  <div class="d-grid gap-2">
    <button
      type="button"
      class="btn btn-primary text-light btn-lg"
      @click="saveQuestionGdpr()"
    >
      <span>Akkoord</span>
    </button>
  </div>
</template>

<script>
export default {
  name: "QuestionGdprScreen",
  data: () => ({}),
  computed: {},
  methods: {
    addRemoveAnswer(answer) {
      if (this.checkedAnswers.indexOf(answer) !== -1) {
        this.checkedAnswers.splice(this.checkedAnswers.indexOf(answer), 1);
      } else {
        this.checkedAnswers.push(answer);
      }
    },
    answerChecked(answer) {
      if (this.checkedAnswers.indexOf(answer) !== -1) {
        return true;
      }
      return false;
    },
    saveQuestionGdpr() {
      let code = this.createCode(5);
      this.$store.commit("setCode", code);
      this.$store.commit("setQuestionGdpr", "true");
      let data = {};
      data.name = this.$store.getters.getFirstName;
      data.last_name = this.$store.getters.getLastName;
      data.phone = this.$store.getters.getPhone;
      data.email = this.$store.getters.getEmail;
      data.company = this.$store.getters.getCompany;
      data.job_position = this.$store.getters.getJobposition;
      data.code = code;
      this.$store.dispatch("saveVisitor", data);
    },
    createCode(length) {
      let result = "";
      const characters = "0123456789";
      const charactersLength = characters.length;
      let counter = 0;
      while (counter < length) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
        counter += 1;
      }
      return result.toString();
    },
  },
};
</script>

<style lang="css" scoped>
h1 {
  font-size: 40px;
  color: #000;
}
.bg-field {
  color: #fdf7ea !important;
}
</style>
