<template lang="html">
  <!-- <img src="@/assets/logo.png" alt="Diensten Centrum" width="200" /> -->
  <h1 class="title text-primary">Vul jouw werk gegevens in</h1>
  <input
    v-model="company"
    class="form-control field no-focusborder"
    type="text"
    ref="inputfield"
    placeholder="Organisatie"
    required
  />
  <input
    v-model="jobposition"
    class="form-control field no-focusborder"
    type="text"
    ref="inputfield"
    placeholder="Functie"
    required
  />
  <div class="d-grid gap-2">
    <button
      type="button"
      class="btn btn-primary text-light btn-lg"
      :disabled="isDisabled == true"
      @click="setWorkData()"
    >
      Volgende
    </button>
  </div>
</template>

<script>
export default {
  name: "WorkDataScreen",
  data: () => ({
    company: null,
    jobposition: null,
  }),
  computed: {
    isDisabled() {
      if (this.company && this.jobposition) {
        return false;
      }
      return true;
    },
  },
  methods: {
    setWorkData() {
      this.$store.commit("setCompany", this.company);
      this.$store.commit("setJobposition", this.jobposition);
    },
  },
};
</script>

<style lang="css" scoped>
h1 {
  font-size: 30px;
  color: #fff;
}
.title {
  padding-bottom: 10px;
}
.field {
  /* text-transform: uppercase; */
  margin: auto;
  outline: none;
  border: none;
  width: 100%;
  padding: 10px;
  font-size: 1.25rem;
  border-radius: 0px;
  text-align: center;
  margin-bottom: 20px;
  color: #f7e374;
  background-color: #512d6d;
}
.field::placeholder {
  color: #f7e374;
  opacity: 0.5;
}
</style>
