import { createStore } from "vuex";
import VuexPersistence from "vuex-persist";
import axios from "axios";
// import router from "../router";

const vuexLocalStorage = new VuexPersistence({
  key: "vuex", // The key to store the state on in the storage provider.
  storage: window.localStorage, // or window.sessionStorage or localForage
  // Function that passes the state and returns the state with only the objects you want to store.
  // reducer: (state) => state,
  // Function that passes a mutation and lets you decide if it should update the state in localStorage.
  // filter: mutation => (true)
});

export default createStore({
  plugins: [vuexLocalStorage.plugin],
  state: {
    loading: false,
    hasActiveGame: false,
    gameState: "inactive",
    playerState: "ended",
    firstName: "",
    lastName: "",
    company: "",
    jobposition: "",
    phone: "",
    email: "",
    questionGdpr: null,
    savedData: null,
    hiScore: {},
    score: 0,
    code: null,
    activeGameDiff: null,
    activeGameUser: null,
    countries: [],
    notfound: false,
  },
  getters: {
    getLoading(state) {
      return state.loading;
    },
    getNotFound(state) {
      return state.notfound;
    },
    getFirstName(state) {
      return state.firstName;
    },
    getLastName(state) {
      return state.lastName;
    },
    getCompany(state) {
      return state.company;
    },
    getJobposition(state) {
      return state.jobposition;
    },
    getPhone(state) {
      return state.phone;
    },
    getEmail(state) {
      return state.email;
    },
    getGameState(state) {
      return state.gameState;
    },
    getPlayerState(state) {
      return state.playerState;
    },
    getQuestionGdpr(state) {
      return state.questionGdpr;
    },
    getCode(state) {
      return state.code;
    },
    getSavedData(state) {
      return state.savedData;
    },
    getActiveGameDiff(state) {
      return state.activeGameDiff;
    },
    getActiveGameUser(state) {
      return state.activeGameUser;
    },
    getCountries(state) {
      return state.countries;
    },
    getScore(state) {
      return state.score;
    },
    getHiScore(state) {
      return state.hiScore;
    },
  },
  mutations: {
    setLoading(state, bool) {
      state.loading = bool;
    },
    setFirstName(state, firstName) {
      state.firstName = firstName;
    },
    setLastName(state, lastName) {
      state.lastName = lastName;
    },
    setCompany(state, value) {
      state.company = value;
    },
    setJobposition(state, value) {
      state.jobposition = value;
    },
    setPhone(state, phone) {
      state.phone = phone;
    },
    setEmail(state, email) {
      state.email = email;
    },
    setGameState(state, value) {
      state.gameState = value;
    },
    setPlayerState(state, value) {
      state.playerState = value;
    },
    setCode(state, value) {
      state.code = value;
    },
    setQuestionGdpr(state, value) {
      state.questionGdpr = value;
    },
    setSavedData(state, savedData) {
      state.savedData = savedData;
    },
    setActiveGameDiff(state, diff) {
      state.activeGameDiff = diff;
    },
    setActiveGameUser(state, diff) {
      state.activeGameUser = diff;
    },
    setCountries(state, value) {
      state.countries = value;
    },
    setScore(state, value) {
      state.score = value;
    },
    updateScore(state, value) {
      state.score = state.score + value;
    },
    setHiScore(state, value) {
      state.hiScore = value;
    },
    setNotFound(state, value) {
      state.notfound = value;
    },
  },
  actions: {
    saveVisitor(state, data) {
      state.commit("setLoading", true);
      let token = process.env.VUE_APP_TOKEN;
      axios
        .post(
          process.env.VUE_APP_COCKPITURL + "/content/item/entries",
          {
            data: data,
          },
          {
            headers: {
              "api-key": token,
            },
          }
        )
        .then((response) => {
          state.commit("setLoading", false);
          state.commit("setSavedData", response.data);
        })
        .catch((err) => console.log(err));
    },
    getPlayer(state, data) {
      state.commit("setLoading", true);
      axios
        .get(
          "https://multiplechoice.quantes.nl/getplayer/index.php?code=" +
            data.code
        )
        .then((response) => {
          console.log(response.data[0]);
          state.commit("setLoading", false);
          state.commit("setNotFound", true);
          if (typeof response.data[0] != "undefined") {
            state.commit("setGameState", "active");
            state.commit("setPlayerState", "start");
            state.commit("setNotFound", false);
            state.commit("setActiveGameUser", response.data[0]);
          }
        })
        .catch((err) => console.log(err));
    },
    getHiScore(state) {
      state.commit("setLoading", true);
      axios
        .get("https://multiplechoice.quantes.nl/gethiscore/")
        .then((response) => {
          state.commit("setLoading", false);
          state.commit("setHiScore", response.data);
        })
        .catch((err) => console.log(err));
    },
    getCountries(state) {
      state.commit("setLoading", true);
      axios
        .get(process.env.VUE_APP_COCKPITURL + "/content/items/countries", {
          headers: {
            "api-key": "API-dde500bd9b21039ed53aa829d16e9f2980428c09",
          },
        })
        .then((response) => {
          state.commit("setLoading", false);
          state.commit("setCountries", response.data);
        })
        .catch((err) => console.log(err));
    },
    updatePlayer(state) {
      state.commit("setLoading", true);
      let user = state.getters.getActiveGameUser;
      let score = state.getters.getScore;
      axios
        .get(
          "https://multiplechoice.quantes.nl/updateplayer/index.php?code=" +
            user.code +
            "&score=" +
            score
        )
        .then(() => {
          state.commit("setLoading", false);
          state.commit("setActiveGameUser", null);
        })
        .catch((err) => console.log(err));
    },
  },
  modules: {},
});
